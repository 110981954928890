
import './App.css';
import HeroSection from './pages/HeroSection';


function App() {
  return (
    <>
    <HeroSection/>
    </>
  );
}

export default App;
